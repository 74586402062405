import * as jose from 'jose'
import { emit } from './eventDispatcher';

export const getIp = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) throw new Error('Erreur lors de la récupération de l’IP');

        const data = await response.json();
        window.localStorage.setItem('ip', data.ip);
        return data.ip; // Retourne l'IP si besoin
    } catch (error) {
        console.error('Impossible de récupérer l’IP:', error);
        return null;
    }
};

export const generateUUID = () => {                             //Génération d'un UUID
    const { v4: uuidv4} = require('uuid');
    return uuidv4();
}

export const getPrenom = () => {                                //Récupération du prénom grâce à un regex sur l'email
    var prenom = ''
    var regexName = new RegExp(/^.*?(?=\.)/)
    if(typeof window.localStorage.email !== 'undefined') {
        prenom = window.localStorage.email.match(regexName)
        return prenom[0].charAt(0).toUpperCase() + prenom[0].slice(1)
    }
}

export const generateURLAffichage = (typeRapport, numRapport, secteur, agence, date_Deb, date_Fin, paramSect, paramAg, paramDateDeb, paramDateFin, METABASE_KEY) => {        //Génération de l'URL d'intégration de rapport Metabase en fonction des paramètres
    var METABASE_SITE_URL = "https://metabase.prod.lirove.fr";
    var dateDeb = new Date(date_Deb);
    var dateFin = new Date(date_Fin);

    if(agence === "") {
        agence = null;
    }
    
    if(secteur === "") {
        secteur = null;
    }

    console.log('dateDeb', dateDeb)
    console.log('dateFin', dateFin)
    console.log('Agence :' + agence);
    console.log('paramAgence :' + paramAg);
    console.log('Secteur :' + secteur);
    console.log('paramSecteur :' + paramSect);
    const param = 
        paramDateDeb && paramDateFin ? 
            paramSect && paramAg ? 
                {
                    [paramSect]: secteur,
                    [paramAg]: agence,
                    [paramDateDeb]: dateDeb,
                    [paramDateFin]: dateFin
                }
            :
                paramSect && !paramAg ? 
                    {
                        [paramSect]: secteur,
                        [paramDateDeb]: dateDeb,
                        [paramDateFin]: dateFin
                    }
                :
                    !paramSect && paramAg ? 
                        {
                            [paramAg]: agence,
                            [paramDateDeb]: dateDeb,
                            [paramDateFin]: dateFin
                        }
                    :
                        {
                            [paramDateDeb]: dateDeb,
                            [paramDateFin]: dateFin
                        }
        :
            paramSect && paramAg ? 
                {
                    [paramSect]: secteur,
                    [paramAg]: agence,
                }
            :
                paramSect && !paramAg ? 
                    {
                        [paramSect]: secteur,
                    }
                :
                    !paramSect && paramAg ? 
                        {
                            [paramAg]: agence,
                        }
                    :
                        null
    var payload = {
        resource: typeRapport === 'question' ? { question: parseInt(numRapport) } : { dashboard: parseInt(numRapport) },
        params: param ? param : { },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration       
    };

    const secret = new TextEncoder().encode(METABASE_KEY)
    const jwt = new jose.SignJWT(payload)
    .setProtectedHeader({alg:"HS256"})
    .sign(secret);

    const urlIframe = (async () => {
        const token = await jwt;
        const url = encodeURI(METABASE_SITE_URL + "/embed/"+ typeRapport + "/" + token + "#bordered=true&titled=true");
        return url;
    })();
    
    return urlIframe
}

export const storeRapport = (nomRapport, typeRapport, numRapport, nomSect, nomAg, dateDeb, dateFin, nomParamSect, nomParamAg, dateParamDeb, dateParamFin) => {
    if(nomRapport || typeRapport || numRapport) {
        window.localStorage.setItem('nomRapport',nomRapport)
        window.localStorage.setItem('typeRapport',typeRapport)
        window.localStorage.setItem('numRapport',numRapport)
    }
    console.log('nomSect', nomSect)
    if(nomSect)
        window.localStorage.setItem('Secteur',nomSect)
    else 
        window.localStorage.setItem('Secteur','')
    if(nomAg)               
        window.localStorage.setItem('Agence',nomAg)
    else
        window.localStorage.setItem('Agence','')
    if(dateDeb)
        window.localStorage.setItem('dateDeb',dateDeb)
    else
        window.localStorage.setItem('dateDeb', new Date())
    if(dateFin)
        window.localStorage.setItem('dateFin',dateFin)
    else
        window.localStorage.setItem('dateFin', new Date())
    if(nomParamSect)
        window.localStorage.setItem('nomParamSecteur',nomParamSect)
    if(nomParamAg)
        window.localStorage.setItem('nomParamAgence',nomParamAg)
    if(dateParamDeb)
        window.localStorage.setItem('dateParamDeb',dateParamDeb)
    if(dateParamFin)
        window.localStorage.setItem('dateParamFin',dateParamFin)

    // Emit localStorage change event
    emit('localStorageChange', { key: 'localStorage', value: window.localStorage });
}

